import React from 'react';
import globalState from "../../global.state";
import { useHookstate } from '@hookstate/core';
import { IoMdFemale, IoMdMale } from "react-icons/io";
import { IoMaleFemale } from "react-icons/io5";


const Flag =({ code })=> {
    return(
        <img style={{width: '25px', height: '25px', marginLeft: '2px'}}
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`}
        />
    );
}
const Likes =({ count })=> {
    const useX =(count: number)=> {
        if(count < 10) return 170;
        else if(count >= 10 && count < 100) return 140;
        else return 70;
    }
    const useCount =()=> {
        if(count >= 1000) {
            const num = Math.round(count / 1000);
            return `${num} к`
        }
        else return count;
    }
    

    return(
        <svg style={{fontSize: '30px'}}
            fill="red"
            height="35px"
            width="35px"
            viewBox="0 0 500 500"
        >
            
            <g id="SVGRepo_iconCarrier">
                <g>
                    <path d="M433.601,67.001c-24.7-24.7-57.4-38.2-92.3-38.2s-67.7,13.6-92.4,38.3l-12.9,12.9l-13.1-13.1 c-24.7-24.7-57.6-38.4-92.5-38.4c-34.8,0-67.6,13.6-92.2,38.2c-24.7,24.7-38.3,57.5-38.2,92.4c0,34.9,13.7,67.6,38.4,92.3 l187.8,187.8c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-3.9l188.2-187.5c24.7-24.7,38.3-57.5,38.3-92.4 C471.801,124.501,458.301,91.701,433.601,67.001z M414.401,232.701l-178.7,178l-178.3-178.3c-19.6-19.6-30.4-45.6-30.4-73.3 s10.7-53.7,30.3-73.2c19.5-19.5,45.5-30.3,73.1-30.3c27.7,0,53.8,10.8,73.4,30.4l22.6,22.6c5.3,5.3,13.8,5.3,19.1,0l22.4-22.4 c19.6-19.6,45.7-30.4,73.3-30.4c27.6,0,53.6,10.8,73.2,30.3c19.6,19.6,30.3,45.6,30.3,73.3 C444.801,187.101,434.001,213.101,414.401,232.701z">
                    </path>
                </g>
            </g>
            <text
                fontSize={220}
                x={useX(count)}
                y={300}
                fill={"white"}
            >
                { useCount() }
            </text>
        </svg>
    );
}
const Sex =({ type })=> {
    const icons = {
        m: <IoMdMale style={{color: 'blue', fontSize: '26px', marginTop: '2px'}} />,
        fem: <IoMdFemale style={{color: 'red', fontSize: '26px', marginTop: '2px'}}/>,
        mf: <IoMaleFemale style={{color: 'silver', fontSize: '26px', marginTop: '2px'}} />
    }
    console.log(type)

    return(
        <React.Fragment>
            { icons[type] &&
                icons[type]
            }
        </React.Fragment>
    );
}


export default function() {
    const user = useHookstate(globalState.user);
    const ovnerState = useHookstate(globalState.ovner); 


    return(
        <div className='Indicator'>
            { ovnerState?.likes?.get() !== undefined &&
                <Likes 
                    count={ovnerState.likes.get()} 
                />
            }
            { ovnerState?.info?.country?.get() !== undefined &&
                <Flag 
                    code={ovnerState.info.country.get()} 
                />
            }
            { ovnerState?.sex?.get() !== undefined &&
                <Sex 
                    type={ovnerState.sex.get()} 
                />
            }
        </div>
    );
}
