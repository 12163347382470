import React from 'react';




export default function({ useInitALarm, value }) {
    return(
        <div className='AlarmContainer'>
            <div className='LabelAlarm'>
                ALARM +18
            </div>
            <div className='AlarmCard'>
                зафиксированная на каком-либо материальном носителе человеческая мысль; в общем плане связная и полная последовательность символов.
                Существуют две основные трактовки понятия «текст»: имманентная (расширенная, философски нагруженная) и репрезентативная (более частная). Имманентный подход подразумевает отношение к тексту как к автономной реальности, нацеленность на выявление его внутренней структуры. Репрезентативный — рассмотрение текста как особой формы представления информации о внешней тексту действительности.
                В лингвистике термин «текст» используется в широком значении, включая и образцы устной речи. Восприятие текста изучается в рамках лингвистики текста и психолингвистики. Так, например, И. Р. Гальперин определяет текст следующим образом: «Это письменное сообщение, объективированное в виде письменного документа, состоящее из ряда высказываний, объединённых разными типами лексической, грамматической и логической связи, имеющее определённый модальный характер, прагматическую установку и соответственно литературно обработанное
            </div>
            <div className='AlarmRadio'>
                <input type="checkbox" 
                    className='AlarmCheckbox'
                    onChange={(e)=> useInitALarm()} 
                    checked={value}
                />
                <div className='AlarmCheckboxLabel'>
                    Соглашаюсь
                </div>
            </div>
        </div>
    );
}