import React from 'react';


export default function() {


    return(
        <div className='AdminBase'>
            
        </div>
    );
}